import { useState, useEffect } from 'react';
import { getPlaces } from '../services/api';

const usePlaces = () => {
  const [placesData, setPlacesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPlaces = async () => {
    try {
      const response = await getPlaces();
      const { data, status } = response;      
      if (status === 200) {      
        setPlacesData(data);
      } else if (status === 204) {
        setPlacesData({});
      } else {
        throw new Error('Failed to fetch places');
      }
    } catch (error) {
      console.log(error)
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchPlaces();
  }, []);

  return { placesData, loading, error };
};

export default usePlaces;
