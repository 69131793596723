import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {translate} from '../../utils/errors';
import { CMS_ROUTES, SITE_ROUTES } from '../../constants';
import * as Yup from 'yup';
import './RegisterForm.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RegisterForm = () => {
  const [captchaValue, setCaptchaValue] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const recaptchaRef = useRef(null);

  
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Ingrese una dirección válida').required('Campo requerido'),
    password: Yup.string().min(8, 'Debe tener al menos 8 caracteres').required('Campo requerido'),
    contact_name: Yup.string().required('Campo requerido'),
    contact_last_name: Yup.string().required('Campo requerido'),
    contact_phone: Yup.string().required('Campo requerido'),
    terms: Yup.boolean().oneOf([true], 'Debe aceptar los términos y condiciones')
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onReCAPTCHAChange = (value) => {
    setCaptchaValue(value);
    setError(null);
  };

  const onSubmit = async (data) => {
    const captchaValue = recaptchaRef.current.getValue();
    if (!captchaValue) {
      setError('Please confirm you are not a robot');
      return;
    }
    data.captcha = captchaValue;

    try {
      const response = await axios.post(`${API_BASE_URL}/auth/register`, data);
      if (response.status === 201) {
        setError(null);
        setSuccess(true);
      } else {
        throw new Error('Error en la registracion.');
      }
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message || 'Registration failed');
    } finally {
      recaptchaRef.current.reset();
    }
  };

  return (
    <div className="register-container">
      {success && (
        <div className="success">
          <p className="title">Confirmado</p>
          <p className="text">
            Una vez conformado el correo, la Asociación aprobará la solicitud de participación.
          </p>
        </div>
      )}
      {!success && (
        <div className="register-card">
          <h2>Registración</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 mt-4">
              <label htmlFor="registerEmail">Dirección de correo electrónico</label>
              <input
                type="email"
                className={`form-control ${errors.email ? 'is-invalid' : ''} mt-2`}
                id="registerEmail"
                {...register('email')}
              />
              {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
            </div>
            <div className="mb-4">
              <label htmlFor="registerPassword">Clave</label>
              <div className="input-group flex-nowrap">            
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  className={`form-control ${errors.password ? 'is-invalid' : ''} mt-2`}
                  id="registerPassword"
                  {...register('password')}
                />
                <span
                  onClick={()=> setPasswordVisible(!passwordVisible)}
                  className="py-3 px-2" 
                  >
                  {passwordVisible ? <FaEyeSlash size={24}/> : <FaEye size={24}/>}
                </span>
              </div>
              {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
            </div>
            <div className="mb-4">
              <label htmlFor="contact_name">Nombre</label>
              <input
                type="text"
                className={`form-control ${errors.contact_name ? 'is-invalid' : ''} mt-2`}
                id="contact_name"
                {...register('contact_name')}
              />
              {errors.contact_name && <div className="invalid-feedback">{errors.contact_name.message}</div>}
            </div>
            <div className="mb-4">
              <label htmlFor="contact_last_name">Apellido</label>
              <input
                type="text"
                className={`form-control ${errors.contact_last_name ? 'is-invalid' : ''} mt-2`}
                id="contact_last_name"
                {...register('contact_last_name')}
              />
              {errors.contact_last_name && <div className="invalid-feedback">{errors.contact_last_name.message}</div>}
            </div>
            <div className="mb-4">
              <label htmlFor="contact_phone">Teléfono</label>
              <input
                type="text"
                className={`form-control ${errors.contact_phone ? 'is-invalid' : ''} mt-2`}
                id="contact_phone"
                placeholder='Ej: 011 4234 5678'
                {...register('contact_phone')}
              />
              {errors.contact_phone && <div className="invalid-feedback">{errors.contact_phone.message}</div>}
            </div>
            <div className="mb-4">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lc5mhwqAAAAALyL8xt2oYiWSv3P_6vRUqDlGaet"
                onChange={onReCAPTCHAChange}
              />
            </div>
            <div className="mb-4">        
              <input
                className="form-check-input"
                type="checkbox"                
                id="terms"
                defaultChecked={true}
                {...register('terms')}
              />          
              <label className="form-check-label ms-2" htmlFor="apyce_member">
                <a href={SITE_ROUTES.TERMINOS}>Acepto términos y condiciones</a>
              </label>
              {errors.terms && <div className="invalid-feedback">{errors.terms.message}</div>}
            </div>
            {error && <div className="alert alert-danger">{translate(error)}</div>}
            <button type="submit" className="btn btn-custom-primary w-100" disabled={isSubmitting}>
              Enviar
            </button>
            <div className="d-flex mt-3">
              <a href={CMS_ROUTES.LOGIN} className="ms-auto">Si ya estas registrado ingresa aca</a>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default RegisterForm;
