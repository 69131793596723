import React from 'react';
import { AiOutlineExclamationCircle } from "react-icons/ai";

import './AlertMessage.css';

const AlertMessage = ({ message, showIcon=true }) => {
  return (
    <div className={`alert alert-danger d-flex align-items-start`} role="alert">
      {showIcon && <span className="icon"><AiOutlineExclamationCircle/>&nbsp;</span>}
      <span>{message}</span>
    </div>
  );
}

export default AlertMessage;
