import { useState, useEffect } from 'react';
import { getPromotion } from '../services/api';

const usePromotion = (id) => {
  const [promotionData, setPromotionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPromotion = async (id) => {
    try {
      const response = await getPromotion(id);
      const {data, status} = response;
      if (status === 200) {             
        setPromotionData(data);
      } else if (status === 204) {
        setPromotionData({});
      } else {              
        throw new Error('Failed to fetch promotion data');
      }
    } catch (error) {      
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPromotion(id);
  }, []);

  return { promotionData, loading, error };
};

export default usePromotion;
