import React from 'react';
import ForgotForm from '../components/ForgotForm';
import './AuthPage.css';


const ForgotPage = () => {
  return (
    <div className="container-fluid auth p-5">
      <div className="row justify-content-md-center">
        <div className="col-lg-4 col-md-6 col-sm-8 col-10 mx-lg-4 mx-md-4 mx-auto mb-5">
          <ForgotForm />
        </div>        
      </div>
    </div>
  );
};

export default ForgotPage;
