import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { resetPassword } from '../services/api';
import { CMS_ROUTES } from '../../constants';
import * as Yup from 'yup';
import './LoginForm.css';  


// Define the schema using Yup
const validationSchema = Yup.object().shape({
  password: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('La contraseña es obligatoria'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('La confirmación de la contraseña es obligatoria'),
});

const ForgotForm = ({token, email}) => {

  const [status, setStatus] = useState(null);
  
  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    try {
      await resetPassword(email, token, data.password);     
      setStatus('SUCCESS');     
    } catch (error) {
      setStatus('ERROR');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Resetea tu clave</h2>        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3 mt-5">
            <label htmlFor="password" className="form-label">Nueva clave</label>
            <input
              type="password"
              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              id="password"
              {...register('password')}
            />
            {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
          </div>   
          <div className="mb-3 mt-5">
            <label htmlFor="confirm_password" className="form-label">Confirmar clave</label>
            <input
              type="password"
              className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`}
              id="confirm_password"
              {...register('confirm_password')}
            />
            {errors.confirm_password && <div className="invalid-feedback">{errors.confirm_password.message}</div>}
          </div>                          
          <button type="submit" className="btn btn-custom-primary w-100" disabled={isSubmitting}>Enviar</button>
          {status === 'SUCCESS' && 
            <>
              <div className="alert alert-success mt-3" role="alert">Clave actualizada correctamente</div> 
              <a href={CMS_ROUTES.LOGIN}>Volver al login</a>
            </>            
          }
          {status === 'ERROR' && <div className="alert alert-danger mt-3" role="alert">Error al actualizar la clave</div> }
        </form>
      </div>
    </div>
  );
};

export default ForgotForm;
