import React, { useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import usePromotions from '../../hooks/usePromotions';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { CMS_ROUTES } from '../../../constants';
import { removePromotion } from '../../services/api';
import Spinner from '../../components/Spinner';
import './UsersListPage.css';

const PromotionListPage = () => {
  
  const [loading, setLoading] = useState(false);
  const { promotions, loading: loadingPromotions, error, setPromotions } = usePromotions();
  const navigate = useNavigate();
  
  const deletePromotion = async (id) => {
    try {
      setLoading(true);
      try {
        await removePromotion(id);      
        setLoading(false);
      } catch (error) {
        alert(error);
        setLoading(false);
      }                    
      setPromotions(promotions.filter((promotion) => promotion.id !== id)); 
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="promotions-page cms">
      {loading && <Spinner />}
      <div className="main-content">
        <Sidebar />
        <div className="content">
          {loadingPromotions && <Spinner />}
          {!loadingPromotions && promotions && (
            <>
              <h2>Promociones</h2>
              <hr />              
              <div className="promotions container p-0 mt-5">
                <div className='d-flex justify-content-end mb-2'>
                  <button type='button' className='btn btn-custom-primary' onClick={() => navigate(CMS_ROUTES.EDIT_PROMOTION)}>Crear Promocion</button>
                </div>
                <PromotionsTable data={promotions} onDelete={deletePromotion} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};


const PromotionsTable = ({ data, onDelete }) => {
  const navigate = useNavigate();
  return (    
    <div className="table-responsive pb-2">
      <table className="table table-sm">
        <thead className="table-dark">
          <tr>
            <th scope="col">Id</th>            
            <th scope="col">Nombre</th>            
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((promotion) => (
            <tr key={promotion.id}>
              <td>{promotion.id}</td>
              <td>{promotion.name}</td>                        
              <td>
                <div className="d-flex flex-row">                   
                  <ButtonTooltip icon={                              
                    <FaPencilAlt
                      size={24}
                      color='grey'
                      style={{ cursor: 'pointer' }}                                        
                      onClick={() => navigate(`${CMS_ROUTES.EDIT_PROMOTION}/${promotion.id}`)}
                      className="mx-2"
                    />   
                    } 
                    text="Editar"
                  />   
                  <ButtonTooltip icon={                              
                    <FaTrashAlt
                      size={24}
                      color='grey'
                      style={{ cursor: 'pointer' }}                                        
                      onClick={() => onDelete(promotion.id)}
                      className="mx-2"
                    />   
                    } 
                    text="Borrar"
                  />                      
                </div>                               
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>    
  );
};

const ButtonTooltip = ({ icon, text }) => {  
  return (
    <div className="button custom-tooltip">
      {icon}
      <span className="tooltiptext">{text}</span>
    </div>
  );
};

export default PromotionListPage;
