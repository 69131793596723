
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const API_KEY = "AIzaSyBaEvJOXnRc37pC4OCbWhH1e2tp6HK2bzM";

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const StoresMap = ({ position, stores }) => {  
  const mapRef = useRef(null);
  const markerClustererRef = useRef(null);

  const createMarkers = useCallback(
    (map) => {
      if (markerClustererRef.current) {
        markerClustererRef.current.clearMarkers();
      }

      let openWindow = null;
      
      // Create markers for stores
      const storeMarkers = stores.map(store => {                  
        const marker = new window.google.maps.Marker({
          position: { lat: store.lat, lng: store.lng },
        });

        const infoWindowContent = `
        <a style="cursor:pointer; text-decoration: none" href=${store.link}>
          <div>
            <div>
              <div style="display:inline-block;width:80px;margin-right:5%; vertical-align:top">
                <img                 
                  src="${store.logo}" 
                  alt="${store.name}" 
                  style="width: 80px; height: auto; margin-bottom: 10px" 
                  onerror="this.onerror=null;this.src='/images/site/default-logo.png';"             
                />
              </div>
              <div style="display:inline-block;width:60%;margin-right:5%">
                <h3 style="font-size:24px">${store.name}</h3>
                <p>${store.address}</p>    
              </div>
            </div>            
            ${store.promotions && store.promotions.map(promotion => `
              <div style="display:inline-block;width:40%;margin-right:5%;vertical-align:top">
                <h5 style="font-size:16px">${promotion.description}</h5>
                <p style="margin:0">${promotion.cash ? "Solo efectivo" : "Todos los medios de pago"} - ${promotion.delivery ? "Delivery " : ""}${promotion.takeaway ? "Takeaway " : ""}${promotion.eatin ? "Salon ":""}</p>
              </div>   
              `).join('')}                                         
            </div>
        </a>`;
      

        const infoWindow = new window.google.maps.InfoWindow({
          content: infoWindowContent,
        });

        marker.addListener('click', () => {                  
          if (openWindow) {            
            openWindow.close();
          }
          infoWindow.open(map, marker);
          openWindow = infoWindow;              
        });

        return marker;
      });

      // Create a MarkerClusterer for store markers
      markerClustererRef.current = new MarkerClusterer({ map, markers: storeMarkers });

      // Create marker for the current position (not clustered)
      const currentPositionMarker = new window.google.maps.Marker({
        position: position,
        title: 'Tu ubicación',
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Custom icon (blue marker)
        },
      });

      // Add the current position marker to the map
      currentPositionMarker.setMap(map);

    },
    [stores, position]
  );

  const onLoad = useCallback((map) => {
    mapRef.current = map;    
    if (stores.length || position) {
      createMarkers(map);
    }    
    
  }, [stores, position, createMarkers]);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: API_KEY,
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={10}
      center={position}
      onLoad={onLoad}
      onUnmount={onUnmount}
    />
  );
};

export default StoresMap;
