import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './cms/styles/overrides.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

axios.defaults.withCredentials = true;

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
);
