import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './cms/pages/AuthPage';
import { HelmetProvider } from 'react-helmet-async';
import PizzeriaPage from './cms/pages/PizzeriaPage';
import PizzeriasListPage from './cms/pages/admin/PizzeriasListPage';
import ForgotPage from './cms/pages/ForgotPage';
import ResetPassPage from './cms/pages/ResetPassPage';
import { CMS_ROUTES, SITE_ROUTES } from './constants';
import PrivateRoute from './cms/components/PrivateRoute';
import { AuthProvider } from './cms/provider/auth-context';
import ConfirmEmailPage from './cms/pages/ConfirmEmailPage';
import HomePage from './site/HomePage';
import AdminRoute from './cms/components/AdminRoute';
import EditPizzeriaPage from './cms/pages/admin/EditPizzeriaPage';
import UsersListPage from './cms/pages/admin/UsersListPage';
import RegisterPage from './cms/pages/RegisterPage';
import LoginPage from './cms/pages/LoginPage';
import TycPage from './site/TycPage';
import StoresPage from './site/StoresPage';
import StorePage from './site/StorePage';
import PromotionListPage from './cms/pages/admin/PromotionListPage';
import EditPromotionPage from './cms/pages/admin/EditPromotionPage';
import CreatePromotionPage from './cms/pages/admin/CreatePromotionPage';
const helmetContext = {};


const App = () => {
  return (
    <div className="App">
      {/* Píxel de seguimiento */}
      <img 
        src="https://tracker.metricool.com/c3po.jpg?hash=dc9579ca2655580cbdca79f9ad0dd3f6" 
        style={{ display: 'none' }} 
        alt="Tracking Pixel"
      />
      <AuthProvider>
        <HelmetProvider context={helmetContext}>

        <Routes>
          <Route path={CMS_ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={SITE_ROUTES.TERMINOS} element={<TycPage />} />
          <Route path={SITE_ROUTES.STORES} element={<StoresPage />} />
          <Route path={SITE_ROUTES.STORE} element={<StorePage />} />
          <Route path={CMS_ROUTES.REGISTER} element={<RegisterPage />} />
          <Route path={CMS_ROUTES.FORGOT} element={<ForgotPage />} />
          <Route path={CMS_ROUTES.RESET_PASS} element={<ResetPassPage />} />
          <Route path={CMS_ROUTES.CONFIRM_EMAIL} element={<ConfirmEmailPage />} />
          <Route
              path={CMS_ROUTES.CURRENT_PIZZERA}
              element={<PrivateRoute element={PizzeriaPage} />}
            />
          <Route
              path={CMS_ROUTES.ADMIN_PIZZERIAS}
              element={<AdminRoute element={PizzeriasListPage} />}
            />
          <Route
              path={`${CMS_ROUTES.EDIT_PIZZERIA}/:id`}
              element={<AdminRoute element={EditPizzeriaPage} />}
            />
          <Route
              path={CMS_ROUTES.ADMIN_PROMOTIONS}
              element={<AdminRoute element={PromotionListPage} />}
            />
          <Route
              path={`${CMS_ROUTES.EDIT_PROMOTION}/:id`}
              element={<AdminRoute element={EditPromotionPage} />}
            />
          <Route
              path={`${CMS_ROUTES.EDIT_PROMOTION}`}
              element={<AdminRoute element={CreatePromotionPage} />}
            />
          <Route
              path={CMS_ROUTES.ADMIN_USERS}
              element={<AdminRoute element={UsersListPage} />}
            />
          <Route path={SITE_ROUTES.HOME} element={<HomePage />} />                                                                         
        </Routes>
        </HelmetProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
