// src/hooks/usePromotions.js
import { useState, useEffect } from 'react';
import { validatePasswordToken } from '../services/api';

const useValidatePasswordToken = (email, token) => {  
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(null);

  useEffect(() => {
    const validate = async () => {

      if(email != null && token != null ){
        try {          
          await validatePasswordToken(token, email);          
          setValid(true);
        }
        catch (error) {          
          setValid(false);
        }finally {          
          setLoading(false);
        }
        
      }
    };

    validate();
  }, []);

  return { valid, loading };
};

export default useValidatePasswordToken;
