import axios from 'axios';

const API_GATEWAY_BASE_URL = process.env.REACT_APP_API_GATEWAY_BASE_URL;

const tokenHeader = () => {
  const token = sessionStorage.getItem('jwt');  
  return {    
      'jwt': token
  }
}

export const login = async (data) => {  
    return axios.post(`${API_GATEWAY_BASE_URL}/auth/login`, data);    
}

export const register = async (data) => {  
  const response = await axios.post(`${API_GATEWAY_BASE_URL}/auth/register`, data);
  if (response.status !== 201) {
    throw new Error('register_error');
  }
  return response;
};

export const logout = async () => {
  const response = await axios.get(`${API_GATEWAY_BASE_URL}/auth/logout`, 
    {headers: tokenHeader()}
  );
  if (!response.ok) {
    throw new Error('Logout failed');
  }

  return response.data;
};

export const validatePasswordToken = async (token, email) => {
  const response = await axios.post(`${API_GATEWAY_BASE_URL}/auth/validate-password-token`, {token, email}, {
    method: 'POST'    
  });

  if (response.status !== 200) {
    throw new Error('invalid_token');
  }
  return true;
};

export const forgotPassword = async (email) => {
  const response = await axios.post(`${API_GATEWAY_BASE_URL}/auth/forgot-password`, {email}, {
    method: 'GET'    
  });

  if (response.status !== 200) {
    throw new Error('forgot_email_error');
  }
  return response.data;
};

export const resetPassword = async (email, token, password) => {
  const response = await axios.post(`${API_GATEWAY_BASE_URL}/auth/reset-password`, {email, token, password}, {
    method: 'POST'    
  });

  if (response.status !== 200) {
    throw new Error('reset_password_error');
  }
  return response.data;
};

export const confirmEmail = async (token) => {
  const response = await axios.post(`${API_GATEWAY_BASE_URL}/auth/confirm`, {token}, {    
    method: 'POST',

  });
  if (response.status !== 201) {
    throw new Error(response.data.message);
  }

  return response.data.message;
};

export const sendConfirmationEmail = async (email) => {
  try{
    const response = await axios.post(`${API_GATEWAY_BASE_URL}/auth/send-confirmation-email`, {email}, {
      method: 'POST'    
    });
    if (response.status !== 200) {
      throw new Error('resend_email_error');
    }
    return response.data;
  }
  catch(error){
    console.log(error);
    throw new Error('resend_email_error');
  }  
}

export const geoDetails = async (address) => {
  const response = await axios.get(`${API_GATEWAY_BASE_URL}/geo/detail`, {
    params: { address },
    headers: tokenHeader()
  });
  return response.data;
};

export const getMyPizzeria = async () => {
  const response = await axios.get(`${API_GATEWAY_BASE_URL}/pizzerias/my-pizzeria`, {   
    headers: tokenHeader()
  });
  const {data, status} = response;
  return {data, status};
};

export const getPizzeria = async (id) => {
  const response = await axios.get(`${API_GATEWAY_BASE_URL}/pizzerias/${id}`, {   
    headers: tokenHeader()
  });
  const {data, status} = response;
  return {data, status};
};

export const listPizzerias = async (page, limit, filters) => {
  const params = {};
  if (page !== undefined) {
    params.page = page;
  }

  if (limit !== undefined) {
    params.limit = limit;
  }
  if(filters.query ){
    params.query = filters.query;
  }
  if(filters.status){
    params.status = filters.status;
  }

  const response = await axios.get(`${API_GATEWAY_BASE_URL}/pizzerias/list`, {   
    headers: tokenHeader(),
    params: params
  });
  const {data, status} = response;
  return {data, status};
};


export const listUsers = async (page, limit, filters) => {
  const params = {};
  if (page !== undefined) {
    params.page = page;
  }

  if (limit !== undefined) {
    params.limit = limit;
  }
  if(filters.query ){
    params.query = filters.query;
  }
  if(filters.verified){
    params.verified = filters.verified;
  }
  if(filters.email){
    params.email = filters.email;
  }

  const response = await axios.get(`${API_GATEWAY_BASE_URL}/users/list`, {   
    headers: tokenHeader(),
    params: params
  });
  const {data, status} = response;
  return {data, status};
};

export const setPizzeriaStatus = async (id, active) => {    
  const response = await axios.patch(`${API_GATEWAY_BASE_URL}/pizzerias/${id}/status`, {active}, {
    headers: tokenHeader()
  });
  
  return response.data
}

export const setEmailConfirmed = async (id, confirmed) => {    
  const response = await axios.patch(`${API_GATEWAY_BASE_URL}/users/${id}/confirm-email`, {confirmed}, {
    headers: tokenHeader()
  });
  
  return response.data
}

export const removePizzeriaByUser = async (id) => {    
  const response = await axios.delete(`${API_GATEWAY_BASE_URL}/pizzerias/user/${id}`, {
    headers: tokenHeader()
  });
  return response.data
}

export const savePizzeria = async (data) => {
  const response = await axios.post(`${API_GATEWAY_BASE_URL}/pizzerias`, data, {
    headers: tokenHeader()
  });
  return response.data
}

export const uploadLogo = async (pizzeriaId, blobUrl) => {
  console.log(blobUrl);
  const response = await fetch(blobUrl);
  console.log(response);
  const blob = await response.blob();
  console.log(blob);
  const formData = new FormData();  
  formData.append('file', blob, 'logo.png');

  try {
    const response = await axios.post(`${API_GATEWAY_BASE_URL}/pizzerias/${pizzeriaId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'jwt': sessionStorage.getItem('jwt')
      },
    });
    console.log('Upload Successful:', response.data);
  } catch (error) {
    console.error('Upload Failed:', error.response?.data || error.message);
  }
}

export const removePromotion = async (id) => {    
  const response = await axios.delete(`${API_GATEWAY_BASE_URL}/promotions/${id}`, {
    headers: tokenHeader()
  });
  return response.data
}

export const getPromotions = async () => {
  const response = await axios.get(`${API_GATEWAY_BASE_URL}/promotions`, {   
    headers: tokenHeader()
  });
  const {data, status} = response;
  return {data, status};
};

export const getPromotion = async (id) => {
  const response = await axios.get(`${API_GATEWAY_BASE_URL}/promotions/${id}`, {   
    headers: tokenHeader()
  });
  const {data, status} = response;
  return {data, status};
};

export const savePromotion = async (data) => {
  const response = await axios.post(`${API_GATEWAY_BASE_URL}/promotions`, data, {
    headers: tokenHeader()
  });
  return response.data
}

export const getPlaces = async () => {
  const response = await axios.get(`${API_GATEWAY_BASE_URL}/places/random`);
  const {data, status} = response;
  return {data, status};
};
