import React from 'react';
import Sidebar from '../components/Sidebar';
import PizzeriaForm from '../components/PizzeriaForm';
import useMyPizzeria from '../hooks/useMyPizzeria';
import './PizzeriaPage.css';  // Ensure to create and style this CSS file

const PizzeriaPage = () => {
  const { pizzeriaData, loading: pizzeriaLoading, error: pizzeriaError, savePizzeriaData } = useMyPizzeria();  

  if (pizzeriaError) {
    return <p>Error: {pizzeriaError.message}</p>;
  }

  return (
    <div className="pizzeria-page cms">      
      <div className="main-content">        
          <Sidebar/>        
        <div className="content">
          {pizzeriaLoading && <p>Cargando...</p>}
          {!pizzeriaLoading && 
            <>
              <h2>{!pizzeriaData.fantasy_name ? 'Crear Pizzeria' : 'Mi Pizzeria'}</h2>    
              <hr/>   
              <PizzeriaForm 
                pizzeriaData={pizzeriaData} 
                savePizzeriaData={savePizzeriaData} 
              />
            </>
          }
          </div>
      </div>
    </div>
  );
};

export default PizzeriaPage;
