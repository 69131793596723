import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../provider/auth-context';
import { CMS_ROUTES } from '../../constants';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const {user} = useAuth();
  return user ? <Element {...rest} /> : <Navigate to={CMS_ROUTES.LOGIN} />;
};

export default PrivateRoute;
