import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import * as Yup from 'yup';
import './LoginForm.css';  
import { CMS_ROUTES } from '../../constants';
import { useAuth } from '../provider/auth-context';
import { forgotPassword } from '../services/api';



// Define the schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),  
});

const ForgotForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const { } = useAuth();
  
  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    try {
      await forgotPassword(data.email);                       
      setStatus('SUCCESS');      
    } catch (error) {
      setStatus('ERROR');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Recupera tu clave</h2>
        { status === 'SUCCESS' && 
          <>
            <div className="alert alert-success">Se ha enviado un email con las instrucciones para recuperar tu clave.</div>
            <a href={CMS_ROUTES.LOGIN}>Volver al login</a>
          </>
        }
        { status === 'ERROR' && <div className="alert alert-danger">No se ha podido enviar el mail para recuperar la clave.</div>}
        { (!status || status !== 'SUCCESS') && 
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3 mt-5">
              <label htmlFor="loginEmail">Email address</label>
              <input
                type="email"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                id="loginEmail"
                {...register('email')}
              />
              {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
            </div>          
            {error && <div className="alert alert-danger">{error}</div>}          
            <button type="submit" className="btn btn-custom-primary w-100" disabled={isSubmitting}>Enviar</button>
          </form>
        }
      </div>
    </div>
  );
};

export default ForgotForm;
