import React from 'react';
import RegisterForm from '../components/RegisterForm';
import './AuthPage.css';

const RegisterPage = () => {
  return (
    <div className="container-fluid auth py-5">
      <div className="row justify-content-md-center">
        
        <div className="col-lg-4 col-md-6 col-sm-8 col-12 mx-lg-4 mx-md-4 mx-0">                    
          <RegisterForm />                    
        </div>
        
      </div>
    </div>
  );
};

export default RegisterPage;
