import { default as React, useCallback, useState, useRef, useEffect } from 'react';
import { CropperRef, Cropper, CropperPreview } from 'react-advanced-cropper';
import AlertMessage from './AlertMessage';
import Modal from 'react-modal';
import 'react-advanced-cropper/dist/style.css';
import './ImageAdvancedCropperModal.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
  },
};

interface Image {
  type?: string;
  src: string;
}

Modal.setAppElement('#root');

const ImageAdvancedCropperModal = ({ isOpen, onRequestClose, onImageCropped }) => {
  const [fileReady, setFileReady] = useState(false);
  const previewRef = useRef(null);
  const cropperRef = useRef(null);
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);

  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onUpdate = (cropper) => {
    previewRef.current?.update(cropper);    
  };

  const onLoadImage = (event) => {
    const { files } = event.target;

    if (files && files[0]) {
      const blob = URL.createObjectURL(files[0]);

      setImage({
        src: blob,
        type: files[0].type,
      });
      setFileReady(true);
    }
    event.target.value = '';
  };

  const resize = (canvas, width, height) => {
    const resizedCanvas = document.createElement('canvas');
    const ctx = resizedCanvas.getContext('2d');
  
    resizedCanvas.width = width;
    resizedCanvas.height = height;
  
    ctx.drawImage(canvas, 0, 0, width, height);
  
    return resizedCanvas.toDataURL();
  };
  
  const onFinish = () => {
    const cropper = cropperRef.current;  
    if (cropper) {
      const canvas = cropper.getCanvas();  
      if (canvas) {
        const url = resize(canvas, 200, 200);        
        setFileReady(false);
        onImageCropped(url);
        onRequestClose();
      }
    }
  };
  

  const onClose = () => {
    setFileReady(false);
    onRequestClose();
  }

  useEffect(() => {
    return () => {
      if (image && image.src) {
        URL.revokeObjectURL(image.src);
      }
    };
  }, [image]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Cropper"
      style={customStyles}
    >
      <div className="modal-header">
        <h2>Seleccionar y recortar imagen</h2>
      </div>
      {!fileReady && (
        <div className="modal-body">
          <p>Por favor, cargue un logo con un formato cuadrado. Podrá ajustarlo utilizando una herramienta de recorte con proporción cuadrada para garantizar que se vea correctamente en el sitio.</p>
          <AlertMessage message={'Tamaño recomendado mayor a 200 x 200 (px)'} />
          <div className="buttons-wrapper">
            <button className="load-image-example__button" onClick={onUpload}>
              <input ref={inputRef} type="file" accept="image/*" onChange={onLoadImage} />
              Seleccionar imagen de la pc
            </button>
          </div>
        </div>
      )}

      {fileReady && (
        <div className="modal-body">
          <div className="d-flex flex-row">
            <div className="p-1" style={{ width: '50%' }}>
              <Cropper
                className="upload-example__cropper"
                src={image && image.src}
                stencilProps={{ aspectRatio: 1 }}
                onUpdate={onUpdate}
                ref={cropperRef}
              />
            </div>
            <div className="p-1" style={{ width: '50%' }}>
              <CropperPreview ref={previewRef} className="preview" />
            </div>
          </div>
          <div className="d-flex flex-column">            
            <div className="buttons-wrapper mb-1">
              <button className="select-image-example__button" onClick={onFinish}>
                Confirmar seleccion
              </button>
            </div>                        
            <div className="buttons-wrapper mb-1">
              <button className="load-image-example__button" onClick={onUpload}>
                <input ref={inputRef} type="file" accept="image/*" onChange={onLoadImage} />
                Seleccionar otra imagen de la pc
              </button>
            </div>
            <div className="buttons-wrappe mb-1">
              <button className="cancel-image-example__button" onClick={onClose}>                  
                Cancelar
              </button>
            </div>            
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ImageAdvancedCropperModal;
