const haversineDistance = (coords1, coords2) => {
  const toRadians = (degrees) => degrees * Math.PI / 180;

  const lat1 = toRadians(coords1.lat);
  const lng1 = toRadians(coords1.lng);
  const lat2 = toRadians(coords2.lat);
  const lng2 = toRadians(coords2.lng);

  const dLat = lat2 - lat1;
  const dLng = lng2 - lng1;

  const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const earthRadiusKm = 6371; // Radio de la Tierra en km
  return earthRadiusKm * c;
};

export const filterByDistance = (pizzerias, referenceCoords, radiusKm) => {    
  return pizzerias
    .map((pizzeria) => {
      const pizzeriaCoords = { lat: pizzeria.lat, lng: pizzeria.lng };
      const distance = haversineDistance(referenceCoords, pizzeriaCoords);      
      return { ...pizzeria, distance };
    })
    .filter((pizzeria) => {                
      return pizzeria.distance <= radiusKm
    })
    .sort((a, b) => a.distance - b.distance);
};