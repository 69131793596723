import React, { useEffect, useState } from 'react';
import {APIProvider, Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import { useParams } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { getPlace } from './services/api';
import Spinner from '../cms/components/Spinner';
import { FaPhoneAlt } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';
import ShareButtons from './components/ShareButtons';
import './StorePage.css';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const API_KEY = "AIzaSyBaEvJOXnRc37pC4OCbWhH1e2tp6HK2bzM";

const StorePage = () => {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState(null);
  const { storeId, branchId } = useParams();
  const [meta, setMeta] = useState({});

  const currentUrl = window.location.href;


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const {data} = await getPlace(storeId, branchId);
        setPosition({lat: parseFloat(data.lat), lng: parseFloat(data.lng)});
        setStore(data);
        const meta = {
          title : `La noche de la pizza y la empanada | ${data.name}`,
          description : `La noche de la pizza y la empanada | ${data.name}`,
          image : data.logo,
        }
        setMeta(meta);
        setLoading(false);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [storeId, branchId]);

  const promoOptions = (promotion) =>{
    return [
      promotion.takeaway && 'Takeaway',
      promotion.delivery && 'Delivery',
      promotion.eatin && 'Salon'
    ].filter(Boolean).join(' | ');

  }

  return (
    <div>
      {!loading &&
        <Helmet>
          {/* General Meta Tags */}
          <title>{meta.title}</title>
                        
          {/* Open Graph Meta Tags for Facebook */}
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
          <meta property="og:image" content={meta.image} />  
          <meta property="og:url" content={currentUrl} />              

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content={meta.image} />
          <meta name="twitter:title" content={meta.title} />
          <meta name="twitter:description" content={meta.description} />
          <meta name="twitter:image" content={meta.image} />        
        </Helmet>
      }
      <Header />
      
      {loading && <Spinner />}
      {!loading && 

      <div className="container store">
        <hr className='mb-5'/>
        <div className='row'>          
          <div className='col-12 col-md-6'>            
            <img className='logo mb-3' src={store.logo} alt={store.name} />
            <h1>{store.name}</h1>
            <p className='address'>{store.address}</p>
            <p className="phone">
              <FaPhoneAlt /> 
              <a href={`tel:${store.phone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                {store.phone}
              </a>
            </p>
            <div className="social mx-auto d-flex flex-row mt-2 mb-2">
              {store.social_facebook &&
                <div className='social-icon-container me-2'>                
                  <a href={`https://www.facebook.com/${store.social_facebook}`} rel="noreferrer" target='_blank'>
                    <img src="/images/site/Facebook.png" alt="facebook" />
                  </a>
                </div>
              }
              {store.social_twitter && 
              <div className='social-icon-container mx-2'>
                <a href={`https://x.com/${store.social_twitter}`} rel="noreferrer" target='_blank'>
                  <img src="/images/site/Twitter.png" alt="twitter" />
                </a>
              </div>
              }
              {store.social_instagram && 
                <div className='social-icon-container mx-2'>
                  <a href={`https://www.instagram.com/${store.social_instagram}`} rel="noreferrer" target='_blank'>
                    <img src="/images/site/Instagram.png" alt="instagram" />
                  </a>
                </div>
              }
              {store.social_youtube && 
              <div className='social-icon-container mx-2'>
                <a href={`https://www.youtube.com/${store.social_youtube}`} rel="noreferrer" target='_blank'>
                  <img src="/images/site/Youtube.png" alt="youtube" />
                </a>
              </div>
              }
            </div>
            <p><a href={store.website} rel="noreferrer" target='_blank'>{store.website}</a></p>
            <div className='mt-5'>
              {store.promotions && store.promotions.map((promotion) => (
                <div key={promotion.id} className='promotion'>
                  <h3 className='promo-title'>{promotion.description}</h3>
                  <p>
                    {promotion.cash ? 'Solo efectivo' : 'Todos los medios de pago'}
                  </p>
                  <p>{promoOptions(promotion)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className='col-12 col-md-6' style={{height: '500px'}}>
            <APIProvider apiKey={API_KEY}>
              <Map
                defaultZoom={14}
                defaultCenter={position}
                gestureHandling={'greedy'}        
              >
              <Marker position={position} />
            </Map>   
          </APIProvider>        
          </div>
          <ShareButtons url={currentUrl} title={meta.title} />
        </div>
      </div>
}
      <Footer />
    </div>
  );
};

export default StorePage;
