export const CMS_ROUTES = {
  FORGOT: '/forgot-password',
  RESET_PASS: '/reset-pass',
  LOGIN: '/login',
  REGISTER: '/register',
  CONFIRM_EMAIL: '/confirm-email',
  LOGOUT: '/cms/logout',  
  CURRENT_PIZZERA: '/cms/pizzeria/current',
  ADMIN_PIZZERIAS: '/cms/admin/pizzerias',
  EDIT_PIZZERIA: '/cms/admin/pizzeria',
  ADMIN_PROMOTIONS: '/cms/admin/promotions',
  EDIT_PROMOTION: '/cms/admin/promotion',
  ADMIN_USERS: '/cms/admin/users',
};

export const SITE_ROUTES = {
  HOME: '/',
  TERMINOS: '/terms',
  STORES: '/locales',
  STORE: '/local/:storeId/sucursal/:branchId/:name'
}

