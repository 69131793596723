// src/hooks/usePromotions.js
import { useState, useEffect } from 'react';
import { getPromotions } from '../services/api';

const usePromotions = () => {
  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await getPromotions(); // Adjust the endpoint as necessary
        setPromotions(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPromotions();
  }, []);

  return { promotions, loading, error, setPromotions };
};

export default usePromotions;
