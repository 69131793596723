import { useState, useEffect } from 'react';
import { getPizzeria } from '../services/api';

const usePizzeria = (id) => {
  const [pizzeriaData, setPizzeriaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPizzeria = async (id) => {
    try {
      const response = await getPizzeria(id);
      const {data, status} = response;
      if (status === 200) {             
        setPizzeriaData(data);
      } else if (status === 204) {
        setPizzeriaData({});
      } else {              
        throw new Error('Failed to fetch pizzeria data');
      }
    } catch (error) {      
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const savePizzeriaData = async (formData) => {
    const method = pizzeriaData && pizzeriaData.id ? 'PUT' : 'POST';
    const endpoint = pizzeriaData && pizzeriaData.id ? `/pizzeria/${pizzeriaData.id}` : '/pizzeria';
    
    try {
      const response = await fetch(endpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Failed to save pizzeria');
      }
      const updatedData = await response.json();
      setPizzeriaData(updatedData);
      return updatedData;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  useEffect(() => {
    fetchPizzeria(id);
  }, []);

  return { pizzeriaData, loading, error, savePizzeriaData };
};

export default usePizzeria;
