// src/components/Promotions.js
import React from 'react';
import usePromotions from '../hooks/usePromotions';
import { useState, useEffect } from 'react';
import './Promotions.css';

const Promotions = ({ promotionsData, onPromotionsUpdate }) => {
  const { promotions, loading, error } = usePromotions();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (promotions.length > 0) {
      const initialData = promotions.map(promotion => {

        const current = promotionsData?.find(p => p.promotionId === promotion.id) || null;
        return {
          promotionId: promotion.id,
          enabled: current ? true : false,
          delivery: current ? current.delivery : false,
          takeaway: current ? current.takeaway : false,
          eatin: current ? current.eatin : false,
          cash: current ? current.cash : false,
        };
      });
      setData(initialData);
      onPromotionsUpdate(initialData);
    }
  }, [promotions, promotionsData]);

  const handleChange = (index, key) => {
    const updatedData = [...data];
    updatedData[index][key] = !updatedData[index][key];
    if (key === 'enabled' && updatedData[index][key] === false) {
      updatedData[index].delivery = false;
      updatedData[index].takeaway = false;
      updatedData[index].eatin = false;
      updatedData[index].cash = false;
    }
    setData(updatedData);
    onPromotionsUpdate(updatedData);
  }


  if (loading) return <p>Cargando promociones...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      {data && promotions && (
        <div className="promotions">
          <table className="table table-borderless table-responsive-sm">
            <thead>
              <tr>
                <th>Activa</th>
                <th>Nombre</th>
                <th className="text-center">Solo efectivo</th>
                <th className="text-center">Tipo de Promoción</th>
              </tr>
            </thead>
            <tbody>
              {promotions.map((promotion, index) => (
                <tr key={promotion.id}>
                  <td>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data[index].enabled === true}
                        onChange={() => handleChange(index, 'enabled')}
                      />
                    </div>
                  </td>
                  <td>{promotion.name}</td>
                  <td>
                    <div className="form-check d-flex justify-content-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={data[index].cash}
                        onChange={() => handleChange(index, 'cash')}
                        disabled={data[index].enabled === false}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="checkbox-container d-sm-flex flex-sm-column flex-md-row align-items-center">

                      <div className="form-check mx-2">
                        <label>Delivery</label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={data[index].delivery}
                          onChange={() => handleChange(index, 'delivery')}
                          disabled={data[index].enabled === false}
                        />
                      </div>
                      <div className="form-check mx-2">
                        <label>Takeaway</label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={data[index].takeaway}
                          onChange={() => handleChange(index, 'takeaway')}
                          disabled={data[index].enabled === false}
                        />
                      </div>
                      <div className="form-check mx-2">
                        <label>Salon</label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={data[index].eatin}
                          onChange={() => handleChange(index, 'eatin')}
                          disabled={data[index].enabled === false}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Promotions;
