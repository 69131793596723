import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaPencilAlt, FaTrash, FaTimes } from 'react-icons/fa'; // Import icons
import GoogleMapWithAutocomplete from './GoogleMapWithAutocomplete';
import './Branches.css';

Modal.setAppElement('#root');

const Branches = ({errors, register, onBranchesUpdate, initBranches=[], defaultName=''}) => {
  const [branches, setBranches] = useState(initBranches);
  const [editBranch, setEditBranch] = useState({ name: '', address: '' });
  const [editingIndex, setEditingIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditBranch({ ...editBranch, [name]: value });
  };

  const handleNewBranch = () => {
    setEditBranch({name: defaultName, address: ''});
    setModalIsOpen(true);
  };

  const handleAddBranch = () => {
    if (!editBranch.name || !editBranch.address) {
      return;
    }
    branches.push(editBranch);
    setBranches([...branches], editBranch);
    onBranchesUpdate(branches);
    setEditBranch({ name: '', address: '' });
    setModalIsOpen(false);
  }

  const handleEditBranch = (index) => {
    setEditBranch(branches[index]);
    setEditingIndex(index);
    setModalIsOpen(true);
    onBranchesUpdate(branches);
  };

  const handleUpdateBranch = () => {
    const updatedBranches = branches.map((branch, index) =>
      index === editingIndex ? editBranch : branch
    );
    setBranches(updatedBranches);
    onBranchesUpdate(updatedBranches);
    setEditingIndex(null);
    setEditBranch({ name: '', address: '' });
    setModalIsOpen(false);
  };

  const handleDeleteBranch = (index) => {
    const updatedBranches = branches.filter((_, i) => i !== index);
    setBranches(updatedBranches);
    onBranchesUpdate(updatedBranches);
  };

  return (
    <div className="container">
      {branches.length === 0 && (
        <div className="alert alert-info mt-3" role="alert">
          No hay sucursales adicionales
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={handleNewBranch}
            >
              Agregar Sucursal              
            </button>
          </div>
        </div>
      </div>
      {branches.length > 0 && (
        <table className="table table-responsive-sm ">
          <thead>
            <tr>
              <th className="w-25">Nombre</th>
              <th className="w-50">Direccion</th>
              <th className="w-25"></th>
            </tr>
          </thead>
          <tbody>
            {branches.map((branch, index) => (
              <tr className='no-border' key={index}>
                <td><small>{branch.name}</small></td>
                <td>
                  <small>{branch.address}</small>
                  <br/>
                  <small>{`Lat: ${branch.lat}, Lng: ${branch.lng}`}</small>
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleEditBranch(index)}
                    className="btn btn-outline-secondary mx-2"
                  >
                    <FaPencilAlt size={20}/>
                  </button>                
                  <button 
                    type="button" 
                    className="btn btn-outline-danger"
                    onClick={() => handleDeleteBranch(index)}
                  >
                    <FaTrash size={20}/>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Edit Branch"
        className="Modal"
        overlayClassName="Overlay"
      >        
        <div className="modal-header">
          <h2>Editar Sucursal</h2>
          <button
            type="button"
            className="closeButton"
            onClick={() => setModalIsOpen(false)}
          >
            <FaTimes size={20} />
          </button>
        </div>
        <div className="form-group">
          <label htmlFor="modal_name">Nombre</label>
          <input
            type="text"
            id="modal_name"
            name="name"
            value={editBranch.name}
            onChange={handleEditInputChange}
            className="form-control"
          />
        </div>
        <GoogleMapWithAutocomplete 
          onSelectLocation={(location) => {
          editBranch.address = location.address;
          editBranch.lat = location.position.lat();
          editBranch.lng = location.position.lng();
          }} 
          register={register} 
          errors={errors} 
          height='300px'
          selectedLocation={{address: editBranch.address, lat: editBranch.lat, lng: editBranch.lng}}
        />

        {editingIndex !== null && (
          <div className="form-group d-flex justify-content-end">            
            <button type="button" onClick={handleUpdateBranch} className="btn btn-custom-primary">
              Actualizar
            </button>
          </div>  
        )}
        {editingIndex === null && (
          <div className="form-group d-flex justify-content-end">
            <button type="button" onClick={handleAddBranch} className="btn btn-custom-primary">
              Agregar
            </button>
          </div>  
        )}
      </Modal>
    </div>
  );
};

export default Branches;
