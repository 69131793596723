import React from 'react';
import './PaginationControls.css';

const PaginationControls = ({ total, page, limit, onPageChange, onLimitChange }) => {
  const totalPages = Math.ceil(total / limit);
  const createPaginationItems = () => {
    const items = [];
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <li key={i} className={`page-item ${i === page ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(i); }}>
            {i}
          </a>
        </li>
      );
    }
    return items;
  };

  return (
    <div className="d-flex justify-content-between align-items-center ">
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(page - 1); }}>
              &laquo;
            </a>
          </li>
          {createPaginationItems()}
          <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); onPageChange(page + 1); }}>
              &raquo;
            </a>
          </li>
        </ul>
      </nav>
      <select className="form-select" value={limit} onChange={onLimitChange} style={{ width: 'auto' }}>
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
      </select>
    </div>
  );
};

export default PaginationControls;
