const Header = () => {

  return (
    <div className='container-fluid header'>
      {/* DESKTOP */}
      <div className="d-none d-md-block">
        <div className="d-flex justify-content-between">
          <div className="logo mx-5 my-3 ">
            <img src="/images/site/header-logo.png" alt="Logo" />
          </div>
          <div className="logo mx-5 my-3">
            <img src="/images/site/apyce-logo-blue.png" alt="Logo" />
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div className="text-center">
        <div className="d-block d-md-none ">
          <div className="logo mx-auto my-3 ">
            <img src="/images/site/header-logo.png" alt="Logo" className="img-fluid"/>
          </div>
          <div className="logo mx-auto my-3">
            <img src="/images/site/apyce-logo-blue.png" alt="Logo" style={{width: '50%'}} className="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;