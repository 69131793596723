import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getPlaces = async () => {
  const response = await axios.get(`${API_BASE_URL}/places`);    
  const {data, status} = response;
  return {data, status};
};

export const getPlace = async (id, branchId) => {
  const response = await axios.get(`${API_BASE_URL}/places/${id}/branch/${branchId || 0}`);      
  const {data, status} = response;
  return {data, status};
};

export const getLocation = async () => {
  const response = await axios.get(`${API_BASE_URL}/places/location`);    
  const {data, status} = response;
  return {data, status};
}
