const translations = {
  "promotions should not be empty": "Debe ingresar al menos una promoción",
  "branches should not be empty": "Debe ingresar al menos una sucursal",
  "At least one of \"delivery\", \"takeaway\", or \"eatin\" must be true": "Debe seleccionar al menos una opcion: \"Delivery\", \"Takeaway\" o \"Salon\"",
  "Email is already confirmed": "El email ya ha sido confirmado anteriormente.",
  "Email not confirmed. Please check your email to confirm your account.": "Email no confirmado. Por favor revise su correo para confirmar su cuenta.",
  "Email already registered": "La dirección de correo ya está registrada. En el caso  de no recordar la contraseña, puede recuperarla en la pantalla de login.",
  "Invalid credentials": "Error de usuario o contraseña",
  "Invalid credentials.": "Error de usuario o contraseña",
};

export const extractErrors = (errorResponse) => {  
  const messages = extractConstraints(errorResponse.data.message);
  return translateConstraints(messages);
};

const extractConstraints = (messages) => {
  let constraints = [];

  const extractFromChildren = (children) => {
    children.forEach(child => {
      if (child.constraints) {
        constraints = constraints.concat(Object.values(child.constraints));
      }
      if (child.children && child.children.length > 0) {
        extractFromChildren(child.children);
      }
    });
  };

  messages.forEach(message => {
    if (message.constraints) {
      constraints = constraints.concat(Object.values(message.constraints));
    }
    if (message.children && message.children.length > 0) {
      extractFromChildren(message.children);
    }
  });

  return constraints;
};

const translateConstraints = (messages) => {
  return messages.map(message => translations[message] || message);
};

export const translate = (message) => {
  return translations[message] || message;
};