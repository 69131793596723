import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);    
  const [loading, setLoading] = useState(true); // Add a loading state
  
  useEffect(() => {
    const token = sessionStorage.getItem('jwt');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);
  
        if (decodedToken.exp && decodedToken.exp < currentTime) {
          console.warn('Token has expired');
          sessionStorage.removeItem('jwt'); 
          setUser(null);
        } else {
          setUser(decodedToken);
        }
      } catch (error) {
        console.error('Invalid token', error);
        setUser(null);
      }
    }
    setLoading(false);
  }, []);

  const setJwt = (access_token) => {
    //set in localstore no use cookie
    sessionStorage.setItem('jwt', access_token);
    const decodedToken = jwtDecode(access_token);     
    console.log(decodedToken);           
    setUser(decodedToken);
  }

  const logout = () => {
    sessionStorage.removeItem('jwt');     
    setUser(null);
  };

  if (loading) {
    return <div>Cargando...</div>; // Show loading while checking token
  }

  return (
    <AuthContext.Provider value={{ user, setJwt, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
