import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const TycPage = () => {

  return (
    <div className="terms">
      <Header />
      <div className="container">
        <div>
        <h1>Noche de la Pizza y la Empanada 2024</h1>
          <p>Lea atentamente los Términos y Condiciones de uso. En caso de estar de acuerdo haga
          clic en <strong>“aceptar”.</strong></p>

          <p><strong>a)</strong> La Asociación de Pizzerías, Casas de Empanadas y Actividades Afines (en adelante
          “APYCE”) pone a disposición de todo establecimiento dedicado a explotar un local
          comercial de venta de pizzas, casa de empanadas y afines dentro de la República
          Argentina (en adelante el “USUARIO”) una plataforma virtual (en adelante la
          “PLATAFORMA”) a efectos que estos últimos puedan utilizarla publicando sus
          productos y servicios vinculados a la actividad (en adelante “PyS”) con la intención de
          que sus clientes (en adelante los “CLIENTES”) conozcan las propuestas del USUARIO.</p>

          <p><strong>b)</strong> APYCE pone a disposición del USUARIO la PLATAFORMA a efectos que este último
          participe de la llamada “Noche de la Pizza y la Empanada” (en adelante el “EVENTO”),
          sin perjuicio que en el futuro pueda aplicarse el uso de la PLATAFORMA a otras
          necesidades y/o servicios.</p>

          <p><strong>c)</strong> Para acceder al uso de la PLATAFORMA y participar del EVENTO el USUARIO deberá
          previamente encontrarse asociado a APYCE como condición y requisito de inscripción.
          A esos efectos, quienes no resulten socios de APYCE, podrán realizar su adhesión
          asociándose, completando el formulario de inicio de para participar en el EVENTO.
          Solo podrán participar en el mismo quienes hubieran cumplimentado dicha gestión y
          se encontraran aprobados por APYCE. La asociación y permanencia resulta de carácter
          gratuito y el asociado gozará de los beneficios que la entidad disponga de acuerdo a
          sus normativas internas para la categoría en la que resulte aplicable, sin perjuicio del
          derecho del USUARIO de solicitar ser elegible para otras categorías de asociados no
          gratuitas. El USUARIO, una vez asociado, deberá respetar el Estatuto de la entidad y
          deberá permanecer como miembro al menos por 6 (seis) meses.</p>

          <p><strong>d)</strong> APYCE pone a disposición del USUARIO la PLATAFORMA para participar del EVENTO,
          la cual estará publicada durante los días previos, y hasta el 15 de septiembre de 2024.
          El USUARIO acepta participar del EVENTO según esta reglamentación. Terminada la
          jornada dejará de publicarse el contenido del USUARIO. Sin perjuicio de ellos APYCE
          podrá poner a disposición del USUARIO nuevamente la PLATAFORMA.</p>

          <p><strong>e)</strong> APYCE solamente pone a disposición del USUARIO la PLATAFORMA, gratuita y de
          libre acceso, como una herramienta tecnológica para que éste introduzca los datos
          que vinculados a sus PyS, los que puedan ser conocidos por sus CLIENTES ingresando al
          siguiente enlace en internet: <a href="https://lanochedelapizzaylaempanada.com.ar/">https://lanochedelapizzaylaempanada.com.ar/</a> o la que
          en el futuro se dispusiere.</p>

          <p><strong>f)</strong> Una vez aceptada la inscripción del USUARIO en el EVENTO, éste se compromete a:</p>
          <ol>
            <li>otorgar a sus clientes un beneficio del 50% (cincuenta por ciento) de descuento
          respecto del precio habitual de lista al que venda sus productos el día anterior al
          EVENTO;</li>
            <li>El USUARIO ofrecerá el beneficio en la categoría “Pizzas”, incluyendo al
          menos cuatro variedades que comercialice habitualmente en su local.</li>
            <li>En la categoría “empanadas” el beneficio será de “3x2” (llevando tres PyS se abonan solo dos)
          incluyendo al menos dos sabores diferentes;</li>
            <li>el USUARIO no está obligado a comercializar ambas categorías (“pizzas” y “empanadas”), pudiendo solo participar en
          una de ellas.</li>
            <li>el USUARIO podrá determinar a su libre criterio si la participación en el
          EVENTO aplica para la venta de “salón”, “delivery” o “take away” o solo alguno de
          ellas, no pudiendo modificar la elección luego de seleccionada;</li>
            <li>USUARIO podrá
          determinar a su libre criterio si la participación en el EVENTO excluye algún sistema de
          cobro, no pudiendo modificar la elección luego de seleccionada;</li>
            <li>que el beneficio
          establecido en este punto deberá ser mantenido por el USUARIO al menos el día del
          EVENTO entre las 19 hasta la hora de cierre.</li>
          </ol>

          <p><strong>g)</strong> APYCE no es responsable por los contenidos que publique el USUARIO en la
          PLATAFORMA ni tiene control alguno sobre los mismos, siendo responsabilidad única
          de éste la veracidad de la información como el servicio que prestare a sus CLIENTES. El
          usuario, aceptando estos Términos y Condiciones, liberará a APYCE de cualquier
          reclamo proveniente de sus CLIENTES o terceros, incluyendo cualquier gasto, pago y/o
          accesoria que APYCE deba abonar por el USUARIO en relación al EVENTO.</p>

          <p><strong>h)</strong> Los CLIENTES no tienen vínculo alguno con APYCE sino con el USUARIO. APYCE no
          oferta, promociona ni vende PyS, como tampoco resulta intermediaria entre el
          USUARIO y el CLIENTE. Los CLIENTES sólo pueden conocer los PyS a través de la
          PLATAFORMA y deberán contactar al USUARIO en forma privada y habitual según los
          canales de atención que éste dispusiere.</p>

          <p><strong>i)</strong> El USUARIO garantiza que no utilizará la PLATAFORMA para promocionar PyS para
          los que no se encuentra habilitado y/o no se encuentren en el comercio. Del mismo
          modo el USUARIO garantiza que la promoción y/o venta que efectuare respecto de
          bebidas alcohólicas la realizará bajo su responsabilidad y liberará a APYCE de cualquier
          reclamo sobre dicha promoción y, sin perjuicio de ello, se compromete a indicar en
          toda publicación las frases “beber con moderación”, “prohibida su venta a menores de
          18 años” de conformidad con lo dispuesto por el art. 5to. De la Ley 24.788 y/o la
          prevista por cualquier otra norma local, modificatoria u ampliatoria.</p>

          <p><strong>j)</strong> El USUARIO presta su conformidad para que su nombre, logo, isologo y cualquier
          otra marca registrada de su propiedad sea exhibida a través de la plataforma.
          Asimismo, el USUARIO reconoce que no hará uso de la PLATAFORMA para publicar
          contenidos falsos, erróneos o sobre los cuales no posea los derechos, deslindando
          APYCE toda responsabilidad sobre los mismos en el USUARIO.</p>

          <p><strong>k)</strong> APYCE no divulgará los datos aportados por el USUARIO a la PLATAFORMA y
          mantendrá la privacidad de los mismos conforme la legislación vigente en materia de
          protección de datos personales según ley 25.326.</p>

          <p><strong>l)</strong> APYCE no es ni será responsable frente al USUARIO por la falta de disponibilidad de
          la plataforma de publicación o el sitio web durante las jornadas previstas en el punto
          b) anterior.</p>

        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TycPage;