import React from 'react';
import ResetPassForm from '../components/ResetPassForm';
import { useLocation } from 'react-router-dom';
import useValidatePasswordToken from '../hooks/useValidatePasswordToken';
import './AuthPage.css';

const ResetPassPage = () => {

  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const email = query.get('email');
  const { loading, valid } = useValidatePasswordToken(email, token);
  
  return (
    <div className="container-fluid auth p-5">
      <div className="row justify-content-center">
        {loading === false && valid === true && (
          <div className="col-md-6 mx-auto">
            <ResetPassForm token={token} email={email}/>
          </div>        
        )}
        {loading === false && valid === false && (
          <div className="col-md-6 mx-auto">
            <div className="login-container">
              <div className="login-card">
                <div className="alert alert-danger">
                  El token de recuperación de contraseña no es válido.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassPage;
