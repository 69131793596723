import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import Branches from './Branches';
import GoogleMapWithAutocomplete from './GoogleMapWithAutocomplete';
import { savePizzeria, uploadLogo } from '../services/api';
import './PizzeriaForm.css';
import Promotions from './Promotions';
import { extractErrors } from '../../utils/errors';
import AlertMessage from './AlertMessage';
import { useAuth } from '../provider/auth-context'; 
import { useNavigate } from 'react-router-dom';
import ImageAdvancedCropperModal from './ImageAdvancedCropperModal';
import Spinner from '../components/Spinner';


const PizzeriaForm = ({pizzeriaData, savePizzeriaData}) => {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState(pizzeriaData || { });  
  const [hasBranches, setHasBranches] = useState(formData.has_branches || false);
  const [branches, setBranches] = useState(pizzeriaData.branches || []);
  const [promotions, setPromotions] = useState(pizzeriaData.promotions || []);
  const [location, setLocation] = useState({    
    lat: parseFloat(pizzeriaData?.lat) || -34.604046,
    lng: parseFloat(pizzeriaData?.lng) || -58.3815609,    
    address: pizzeriaData?.address || '',
  });

  const [logoPreview, setLogoPreview] = useState(pizzeriaData?.logoPath || '');
  const [newLogo, setNewLogo] = useState(null);

  const [showCropper, setShowCropper] = useState(false);


  const handleImageCropped = (blob) => {
    setNewLogo(blob);
    setLogoPreview(blob);
  };

  const addHttpsIfMissing = () => {
    return Yup.string().transform((value, originalValue) => {
      if (originalValue && !originalValue.startsWith('http://') && !originalValue.startsWith('https://')) {
        return `http://${originalValue}`;
      }
      return originalValue;
    });
  };


  const validationSchema = Yup.object().shape({
    fantasy_name: Yup.string().required('Campo requerido'),
    social_facebook: Yup.string().nullable(),
    social_instagram: Yup.string().nullable(),
    social_twitter: Yup.string().nullable(),
    social_youtube: Yup.string().nullable(),
    website: addHttpsIfMissing().url().nullable(),
    contact_name: Yup.string().required('Campo requerido'),
    contact_last_name: Yup.string().required('Campo requerido'),
    contact_phone: Yup.string().required('Campo requerido'),
    phone: Yup.string().required('Campo requerido')
  });

  const { register, handleSubmit, control, formState: { errors }, setValue, trigger, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });
  
  const onSubmit = async (data) => {
    toast.dismiss();
    setLoading(true);
    const toastId = toast.loading("Guardando pizzeria...");
    try {
      data.id = pizzeriaData.id;   
      data.has_branches = branches?.length > 0;          
      if (data.has_branches) {      
        data.branches = branches;        
      }      
      data.lat = location.lat;
      data.lng = location.lng;        
      data.address = location.address;              
      data.promotions = promotions
        .filter(p => p.enabled === true)
        .map(p => {          
          const { enabled, ...fields } = p;
          return fields;
      });
      const {id} = await savePizzeria(data);
      if(newLogo) {
        toast.update(toastId, { render: "Actualizando el logo!", type: "success", theme:"colored", position:"top-center",isLoading: false,  autoClose: 5000 });      
        await saveLogo(id, newLogo);
      }
      setLoading(false);
      toast.update(toastId, { render: "Pizzeria actualizada!", type: "success", theme:"colored", position:"top-center",isLoading: false,  autoClose: 5000 });      
      data.id = id;
    } catch (error) {
      setLoading(false);
      const errors = extractErrors(error.response);        
      errors.forEach(error => toast.update(toastId, { render: error, type: "error", isLoading: false, autoClose: 5000 }));            
      setLoading(false);
    }
  };

  const saveLogo = async (pizzeriaId, logo) => {  
    uploadLogo(pizzeriaId, logo);
  
  }
  const handleOpenCrop = () => setShowCropper(true);
  const handleCloseCrop = () => setShowCropper(false);


  const handleBack = () => {
    navigate(-1);
  };

  const removeProtocol = (url) => {
    if(!url) return '';
    return url.replace(/^https?:\/\//, '');
  }

  return (
    <div className="form-container">      
      {loading && <Spinner />}
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>        
        <div className="form-group">
          <label htmlFor="fantasy_name">Nombre de la pizzeria</label>
          <input
            type="text"
            id="fantasy_name"
            defaultValue={formData.fantasy_name}
            {...register('fantasy_name')}
            className={`form-control ${errors.fantasy_name ? 'is-invalid' : ''}`}
          />
          {errors.fantasy_name && <div className="invalid-feedback">{errors.fantasy_name.message}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Teléfono</label>
          <input
            type="text"
            id="phone"
            placeholder='Ej: 011 4234-5678'
            defaultValue={formData.phone}
            {...register('phone')}
            className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
          />
          {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
        </div>
        <br/>
        <div className="form-check form-check-inline">
          <Controller
            name="apyce_member"
            control={control}
            defaultValue={formData.apyce_member || false}
            render={({ field }) => (
              <>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="apyce_member_yes"
                    value="true"
                    {...field}
                    checked={field.value === true}
                    onChange={() => field.onChange(true)}
                  />
                  <label className="form-check-label" htmlFor="apyce_member_yes">
                    Soy socio APYCE
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="apyce_member_no"
                    value="false"
                    {...field}
                    checked={field.value === false}
                    onChange={() => field.onChange(false)}
                  />
                  <label className="form-check-label" htmlFor="apyce_member_no">
                    No soy socio APYCE
                  </label>
                </div>
              </>
            )}
          />
          <AlertMessage showIcon={false} message='Para inscribirse y participar en La Noche de la Pizza y la Empanada deberá completar el siguiente formulario. Con el mismo se aceptan los Términos y Condiciones que incluyen la asociación a APYCE en el carácter de "invitado", sin costo alguno (actual ni futuro).' />  
        </div>        
        <br />
        <h4>Logo</h4>
        <hr/>
        <div className="form-group">
          <ImageAdvancedCropperModal
            isOpen={showCropper}
            onRequestClose={handleCloseCrop}
            onImageCropped={handleImageCropped}
          />
          {logoPreview && (
            <div className="mt-3">
              <img src={logoPreview} alt="Imagen recortada" />
            </div>
            )}
          <br/>
          <button type="button" onClick={handleOpenCrop} className="btn btn-sm btn-custom-secondary">
            Editar logo
          </button>
        </div>
        <br />
        <br />
        <h4 className='mb-3'>Redes sociales de su comercio</h4>
        <hr/>    
        <div className="form-group">
          <label htmlFor="social_facebook">Facebook</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon3">https://www.facebook.com/</span>
            <input
              type="text"
              id="social_facebook"
              defaultValue={formData.social_facebook}
              {...register('social_facebook')}
              className={`form-control ${errors.social_facebook ? 'is-invalid' : ''}`}
              placeholder='appyce'
            />
          </div>
          {errors.social_facebook && <div className="invalid-feedback">{errors.social_facebook.message}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="social_instagram">Instagram</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon3">https://www.instagram.com/</span>
            <input
              type="text"
              id="social_instagram"
              defaultValue={formData.social_instagram}
              {...register('social_instagram')}
              className={`form-control ${errors.social_instagram ? 'is-invalid' : ''}`}
              placeholder='apyce.ar'
            />
          </div>
          {errors.social_instagram && <div className="invalid-feedback">{errors.social_instagram.message}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="social_twitter">Twitter</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon3">https://x.com/</span>            
            <input
              type="text"
              id="social_twitter"
              defaultValue={formData.social_twitter}
              {...register('social_twitter')}
              className={`form-control ${errors.social_twitter ? 'is-invalid' : ''}`}
              placeholder='apyce.ar'
            />
          </div>
          {errors.social_twitter && <div className="invalid-feedback">{errors.social_twitter.message}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="social_youtube">YouTube</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon3">https://www.youtube.com/</span>   
            <input
              type="text"
              id="social_youtube"
              defaultValue={formData.social_youtube}
              {...register('social_youtube')}
              className={`form-control ${errors.social_youtube ? 'is-invalid' : ''}`}
              placeholder='@apyceArgentina'
            />
          </div>
          {errors.social_youtube && <div className="invalid-feedback">{errors.social_youtube.message}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="website">Website</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon3">http://</span> 
            <input
              type="text"
              id="website"
              {...register('website')}
              defaultValue={removeProtocol(formData.website)}
              className={`form-control ${errors.website ? 'is-invalid' : ''}`}
              placeholder='www.apyce.org.ar'
            />
          </div>
          {errors.website && <div className="invalid-feedback">{errors.website.message}</div>}
        </div>

        <br />
        <h4>Datos de contacto</h4>
        <hr />
        <AlertMessage message="Estos datos no se mostrarán en el sitio" />

        <div className="form-group">
          <label htmlFor="contact_name">Nombre</label>
          <input
            type="text"
            id="contact_name"
            defaultValue={formData.contact_name}
            {...register('contact_name')}
            className={`form-control ${errors.contact_name ? 'is-invalid' : ''}`}
          />
          {errors.contact_name && <div className="invalid-feedback">{errors.contact_name.message}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="contact_last_name">Apellido</label>
          <input
            type="text"
            id="contact_last_name"
            defaultValue={formData.contact_last_name}
            {...register('contact_last_name')}
            className={`form-control ${errors.contact_last_name ? 'is-invalid' : ''}`}
          />
          {errors.contact_last_name && <div className="invalid-feedback">{errors.contact_last_name.message}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="contact_phone">Teléfono</label>
          <input
            type="text"
            id="contact_phone"
            placeholder='Ej: 011 4234 5678'
            defaultValue={formData.contact_phone}
            {...register('contact_phone')}
            className={`form-control ${errors.contact_phone ? 'is-invalid' : ''}`}
          />
          {errors.contact_phone && <div className="invalid-feedback">{errors.contact_phone.message}</div>}
        </div>
        <br />
        <div>                        
            <div>
              <br />
              <h4>Direccion del local</h4>
              <hr />
              <GoogleMapWithAutocomplete 
              selectedLocation={location}
              onSelectLocation={(location) => {
                setLocation({
                  lat : location.position.lat(),
                  lng : location.position.lng(),
                  address: location.address
                });
                setValue('address', location.address);
                trigger('address');
              }} register={register} errors={errors} />
            </div>
            <div>
              <br />
              <h4>Sucursales</h4>
              <hr />
              <Branches register={register} errors={errors} initBranches={branches} onBranchesUpdate={setBranches} defaultName={formData.fantasy_name}/>
            </div>          
        </div>
        <div>                      
            <br />
            <h4>Promociones</h4>
            <hr />
            <Promotions promotionsData={pizzeriaData?.promotions} onPromotionsUpdate={setPromotions}/>
            
        </div>

        <ToastContainer />
        <div style={{position:'fixed', bottom: 0, width: '100%', padding: '10px 0', backgroundColor: 'white'}}>
          <div className="form-group">
            <button type="submit" className="px-5 btn btn-custom-primary">Guardar</button>
            {user.admin && <button type="button" className="mx-5 px-5 btn btn-custom-secondary" onClick={handleBack}>Cancelar</button>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PizzeriaForm;
