import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import * as Yup from 'yup';
import './LoginForm.css';  
import { CMS_ROUTES } from '../../constants';
import { useAuth } from '../provider/auth-context';
import { translate } from '../../utils/errors';
import { sendConfirmationEmail } from '../services/api';
import { login } from '../services/api';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Define the schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
});

const LoginForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [email, setEmail] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setJwt, user } = useAuth();
  
  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const resendConfirmationEmail = async () => {
    try {
      await sendConfirmationEmail(email);
      setMessage('El mail ha sido reenviado');
      setError(null);
    }
    catch(error){
      console.error('Error al reenviar mail:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.msg : error.msg || 'Error al reenviar mail.');
      setMessage(null);
    }
  }

  useEffect(() => {
    if(user){
      navigate(user.admin ? CMS_ROUTES.ADMIN_PIZZERIAS : CMS_ROUTES.CURRENT_PIZZERA);
    }
  }, [user]);

  const onSubmit = async (data) => {
    try {
      const response = await login(data)    
      console.log(response);  
      if(response.status === 200){
        console.log(response.data)
        setJwt(response.data.access_token);                
      }
      else{        
        setError('Invalid credentials'); 
      }

    } catch (error) {   
      console.log(error);             
      setEmailNotVerified(false);
      if(error.response?.data.code === 'email_not_confirmed'){
        setEmail(data.email)
        setEmailNotVerified(true);
      } 
      setError(error.response?.data.msg ? error.response.data.msg : 'Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Acceder</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 mt-4">
            <label htmlFor="loginEmail">Dirección de correo electrónico</label>
            <input
              type="email"
              className={`form-control ${errors.email ? 'is-invalid' : ''} mt-2`}
              id="loginEmail"
              {...register('email')}
            />
            {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
          </div>
          <div className="mb-4">
            <label htmlFor="loginPassword">Clave</label>
            <div className="input-group flex-nowrap">            
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  className={`form-control ${errors.password ? 'is-invalid' : ''} mt-2`}
                  id="registerPassword"
                  {...register('password')}
                />
                <span
                  onClick={()=> setPasswordVisible(!passwordVisible)}
                  className="py-3 px-2" 
                  >
                  {passwordVisible ? <FaEyeSlash size={24}/> : <FaEye size={24}/>}
                </span>
              </div>

            {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
          </div>

          {error && <div className="alert alert-danger">
            {translate(error)}
            &nbsp;
            {emailNotVerified && <a href="#" onClick={resendConfirmationEmail}>Reenviar mail</a>}
          </div>}   
          {message && <div className="alert alert-success">{message}</div>}      
          <button type="submit" className="btn btn-custom-primary w-100 mt-3" disabled={isSubmitting}>Ingresar</button>
          <div className="d-flex mt-3">
            <a href={CMS_ROUTES.FORGOT} className="ms-auto">Olvide mi clave</a>
          </div>
          <div className="d-flex mt-3">
              <a href={CMS_ROUTES.REGISTER} className="ms-auto">Si no estas registrado ingresa aca</a>
            </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
