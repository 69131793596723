import { useState, useEffect } from 'react';
import { listUsers } from '../services/api';

const useUsers = (page, limit, filters) => {
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPizzerias = async () => {
    try {
      const response = await listUsers(page, limit, filters);
      const { data, status } = response;
      if (status === 200) {
        setUsersData(data);
      } else if (status === 204) {
        setUsersData({});
      } else {
        throw new Error('Failed to fetch users');
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchPizzerias();
  }, [page, limit, filters]);

  return { usersData, loading, error, setUsersData };
};

export default useUsers;
