import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import usePlaces from './hooks/usePlaces';
import { useNavigate } from 'react-router-dom';
import { filterByDistance } from '../utils/distance';
import { FaPhoneAlt } from "react-icons/fa";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import StoresMap from './components/StoresMap';
import Spinner from '../cms/components/Spinner';
import { SITE_ROUTES } from '../constants';
import './StoresPage.css';

const PAGE_SIZE = 20;
const DISTANCE = 50;
const DEFAULT_COORDINATES = {lat: -34.603646, lng: -58.381135};

const StoresPage = () => {
const navigate = useNavigate();
const { placesData, loading: loadingPlaces } = usePlaces();
const [coordinates, setCoordinates] = useState(null);
const [errorLocation, setErrorLocation] = useState(null);
const [places, setPlaces] = useState([]);
const [filteredPlaces, setFilteredPlaces] = useState([]);
const [filter, setFilter] = useState({name: null, distance: DISTANCE, address: null});
const [limit, setLimit] = useState(PAGE_SIZE);
const [loading, setLoading] = useState(true);

useEffect(() => {  
  if (navigator.geolocation) {         
    navigator.geolocation.getCurrentPosition(
      (position) => {    
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        setErrorLocation(true)        
        const filterCopy = {...filter};
        filterCopy.distance = null;
        setFilter(filterCopy);            
        console.log(err);
        setCoordinates(DEFAULT_COORDINATES);        
      }
    );
  } else {    
    setErrorLocation(true);
    const filterCopy = {...filter};
    filterCopy.distance = null;
    setFilter(filterCopy);       
    console.log('Geolocation is not supported by this browser.');
    setCoordinates(DEFAULT_COORDINATES);        
  }
}, []);

useEffect(() => {  
  if(coordinates && placesData){   
    addLinks(placesData);
    setLoading(true); 
    const {name, distance, address} = filter;
    let filteredPlaces = placesData
    .filter(place => place.lat !== null && place.lng !== null)
    .map(place => ({
        ...place,
        lat: parseFloat(place.lat),
        lng: parseFloat(place.lng)
    }));
    if(name){
      filteredPlaces = filteredPlaces.filter(
        place => 
          place.name.toLowerCase().includes(name.toLowerCase())          
      );
    }
    if(address){
      filteredPlaces = filteredPlaces.filter(
        place => 
          place.address.toLowerCase().includes(address.toLowerCase())          
      );
    }      
    if(distance && placesData && coordinates){      
      filteredPlaces = filterByDistance(filteredPlaces, coordinates, distance);  
    }    
    setLimit(PAGE_SIZE);
    setFilteredPlaces(filteredPlaces);    
    setPlaces(filteredPlaces.slice(0, limit));
    setLoading(false);
  }
}, [placesData, loadingPlaces, coordinates, filter]);


const handleMore = () => {  
  const newLimit = limit + PAGE_SIZE;
  setLimit(newLimit);  
  setPlaces(filteredPlaces.slice(0, newLimit));
}

const handleLogoError = (event) => {
  event.target.src = '/images/site/default-logo.png';
};

const handlePlaceClick = (place) => () => {
  navigate(getLink(place));  
}

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // Smooth scroll animation
  });
};

const slugify = (text) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

const getLink = (place) => {
  const slugifiedName = slugify(place.branch_name || place.name);
  return SITE_ROUTES.STORE
    .replace(':storeId', place.pizzeria_id)
    .replace(':branchId', place.branch_id || '0')
    .replace(':name', slugifiedName);
};

const addLinks = (places) => {
  places.forEach(place => {
    place.link = getLink(place);
  });
}

  return (
    <div className="terms"> 
      {loading && <Spinner />}           
      <Header />
      {!loading &&
      <>
        {coordinates.lat && coordinates.lng && coordinates.lat && <StoresMap position={coordinates} stores={filteredPlaces} /> }
        {errorLocation && <div className="alert alert-danger" role="alert">Sin acceso a la ubicacion por favor dar permisos.</div>}
        <div className='container-fluid filter-container'>
          <div className="container">
            <div className='row'>
              <div className='col-8'>
                <input className="py-1 px-3" type="text" placeholder="Buscar participantes" onChange={(e) => setFilter({...filter, name: e.target.value})} />
              </div>
              <div className='col-4'>
                <input className="py-1 px-3" type="text" placeholder="Donde?" onChange={(e) => setFilter({...filter, address: e.target.value})} />
              </div>
            </div>
            { filter.distance !== null && 
              <div className='row mt-4'>
                <div className='col-12 d-flex flex-row justify-content'>
                  <label className='radius' style={{whiteSpace: "nowrap"}}>Radio {filter.distance} km</label>
                  <input                 
                    type="range" 
                    className="custom-range ms-3" 
                    min="1" 
                    max={DISTANCE} 
                    step="1" 
                    id="customRange3" 
                    onChange={(e) => setFilter({...filter, distance: e.target.value})}
                    defaultValue={filter.distance}
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <div className="container places">
          <div>            
            {places.map((place, index) => (
              
              <div className="d-flex flex-row my-3 place" key={index} onClick={handlePlaceClick(place)}>              
                <div className="logo-container">
                  <img 
                    className="logo" 
                    src={place.logo} 
                    alt={place.branch_name || place.name} 
                    onError={handleLogoError}
                  />
                </div>
                <div className='ps-3 ps-md-4'>
                  <h3 className="name">{place.branch_name || place.name}</h3>
                  <p className="address">{place.address}</p>
                  {place.phone && (
                    <p className="phone">
                      <FaPhoneAlt /> 
                      <a href={`tel:${place.phone}`} style={{ textDecoration: 'none', color: 'inherit', 'marginLeft': '5px' }}>
                        {place.phone}
                      </a>
                    </p>
                  )}
                </div>                
                <div className="flex-fill d-flex justify-content-end align-items-end">
                  <div className="arrow">
                    <a href={getLink(place)}><IoArrowForwardCircleOutline size={32}/></a>
                    </div>
                </div>
              </div>
            ))}          
            <div className='d-flex flex-row justify-content-center'>
              {filteredPlaces.length > limit && <button type="button" style={{width: '300px'}} className="btn btn-custom-primary" onClick={handleMore}>Ver más</button>}
              {filteredPlaces.length <= limit && <button type="button" style={{width: '300px'}} className="btn btn-custom-secondary" onClick={scrollToTop}>Ir al inicio</button>}
            </div>
          </div>
        </div>
        </>
      }
      <Footer />
    </div>
  ) 
}

export default StoresPage;