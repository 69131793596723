import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../provider/auth-context';
import { CMS_ROUTES } from '../../constants';
import './Sidebar.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth()
  const handleLogout = () => {
    logout();
    navigate(CMS_ROUTES.LOGIN);
  };
  return (
    <div id="sidebar" className="custom-sidebar border-end d-sm-flex flex-sm-row d-xs-flex flex-xs-row  flex-md-column">
      <img src="/images/cms/logo.png" alt="logo" className="logo me-sm-3 mb-md-3"/>
      <div className="list-group list-group-flush mt-md-5 mt-sm-0">        
        <span className="list-group-item email">{user.email}</span>
        {!user.admin && <a href={CMS_ROUTES.CURRENT_PIZZERIA} className="list-group-item">Mi Pizzeria</a>}
        {user.admin && <a href={CMS_ROUTES.ADMIN_PIZZERIAS} className="list-group-item">Pizzerias</a>}
        {user.admin && <a href={CMS_ROUTES.ADMIN_USERS} className="list-group-item">Usuarios</a>}
        {user.admin && <a href={CMS_ROUTES.ADMIN_PROMOTIONS} className="list-group-item">Promociones</a>}
        <a href="#" onClick={handleLogout} className="list-group-item">Salir</a>                                
      </div>
  </div>
  );
};
export default Sidebar;
