import React from 'react';
import Sidebar from '../../components/Sidebar';
import PromotionForm from '../../components/PromotionForm';
import './EditPizzeriaPage.css';  // Ensure to create and style this CSS file

const CreatePromotionPage = () => {

  return (
    <div className="pizzeria-page cms">      
      <div className="main-content">        
        <Sidebar/>        
        <div className="content">            
            <h2>Crear Promocion</h2>    
            <hr/>   
            <PromotionForm promotionData={{name:''}}/>          
          </div>
      </div>
    </div>
  );
};

export default CreatePromotionPage;
