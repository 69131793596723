import './Footer.css';

const Footer = () => {

  return (
    <div className='footer'>
      <div className='whatsapp-button-container mt-2 mx-auto'>
        <a href="https://wa.me/5491123440567"
            target="_blank" 
            rel="noopener noreferrer" 
            className="btn btn-whatsapp">
          <img width='100' src='/images/site/whatsapp.webp' alt='whatsapp'/>
        </a>
      </div>
      <div className="col-12 p-0 p-md-5 d-flex flex-row">
        <div className="d-flex mt-5 mb-3 col-4 col-md-7 d-flex flex-column flex-md-row">
          <div>
            <img className="footer-logo" src="/images/site/apyce-logo.png" alt="Logo" />
          </div>
          <div className="d-flex">
            <div className='social-icon-container m-1 m-md-2'>
              <a href="https://www.facebook.com/appyce/" rel="noreferrer" target='_blank'>
                <img src="/images/site/Facebook-white.png" alt="facebook" />
              </a>
            </div>
            <div className='social-icon-container m-1 m-md-2'>
              <a href="https://x.com/APPYCE" rel="noreferrer" target='_blank'>
                <img src="/images/site/Twitter-white.png" alt="facebook" />
              </a>
            </div>
            <div className='social-icon-container m-1 m-md-2'>
              <a href="https://www.instagram.com/apyce.ar/" rel="noreferrer" target='_blank'>
                <img src="/images/site/Instagram-white.png" alt="facebook" />
              </a>
            </div>
            <div className='social-icon-container m-1 m-md-2'>
              <a href="https://www.youtube.com/@apyceArgentina" rel="noreferrer" target='_blank'>
                <img src="/images/site/Youtube-white.png" alt="facebook" />
              </a>
            </div>
          </div>         
        </div>
        <div className="links d-flex mt-5 col-7 col-md-5">
          <div className="my-1 me-1 me-md-4">
            <a href="https://apyce.org/" rel="noreferrer" target='_blank'>Asociación</a>
          </div>
          <div className="my-1 mx-1 mx-md-4">
            <a href="https://apyce.org/escuela-apyce/" rel="noreferrer" target='_blank'>Escuela Apyce</a>
          </div>
          <div className="my-1 mx-1 mx-md-4">
            <a href="https://apyce.org/autoridades/" rel="noreferrer" target='_blank'>Quienes somos</a>
          </div>
        </div>
      </div>
      <div className="col-12 p-0 px-md-5 mb-3 mb-md-5 d-flex flex-row">
        <div className="offset-4 col-8 col-md-5 offset-md-7 copyright d-flex justify-content-start">
          <p>LNPE 2024®. Powered by BDG Buenos Aires</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;