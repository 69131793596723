import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { confirmEmail } from '../services/api';
import { translate } from '../../utils/errors';
import { CMS_ROUTES } from '../../constants';

const STATUS = {
  PROCESSING: 'Processing...',
  EMAIL_CONFIRMED: 'Email confirmed',
  CONFIRMATION_FAILED: 'Confirmation failed'
}

const EmailConfirmation = () => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const isCalledRef = useRef(false);

  useEffect(() => {
    if(token && !isCalledRef.current){
      isCalledRef.current = true;
      setStatus(STATUS.PROCESSING);
      confirmEmail(token)
      .then(response => {
        setStatus(STATUS.EMAIL_CONFIRMED);
      })
      .catch(error => {        
        setStatus(STATUS.CONFIRMATION_FAILED);
        setError(error.response.data.message);
      });
    } else {
      setStatus('Invalid token.');
    }
  }, [token]);

  return (
    <div className="back-container container-fluid">    
      {status === STATUS.EMAIL_CONFIRMED && 
        <div className="form-container mx-auto">  
          <div className="success">
            <h1 className="title">Email confirmado</h1>            
            <p className="text">Se ha confirmado el mail.</p>
            <a href={CMS_ROUTES.LOGIN}>Ir al Login</a>          
          </div>
        </div>
      }
      {status === STATUS.CONFIRMATION_FAILED && 
        <div className="form-container mx-auto col-4">
          <div className="error">
            <h1 className="title">Error</h1>                          
            <p className="text">{translate(error)}</p>      
            <a href={CMS_ROUTES.LOGIN}>Ir al Login</a>
          </div>
        </div>
      }      
    </div>
  );
};

export default EmailConfirmation;
