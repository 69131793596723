import React, {useState, useEffect, useRef} from 'react';
import {APIProvider, Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import './GoogleMapWithAutocomplete.css';

const API_KEY = "AIzaSyBaEvJOXnRc37pC4OCbWhH1e2tp6HK2bzM";

const GoogleMapWithAutocomplete = ({selectedLocation, onSelectLocation, register, errors, width='100%', height='400px'}) => {

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [position, setPosition] = useState({
    lat: selectedLocation?.lat || -34.604046, 
    lng: selectedLocation?.lng || -58.3815609
  });

  useEffect(() => {
    if (selectedPlace) {
      setPosition(selectedPlace.geometry.location);
      onSelectLocation({position: selectedPlace.geometry.location, address: selectedPlace.formatted_address});
    }
  }
  , [selectedPlace]);

  return (
    <APIProvider apiKey={API_KEY}>
      <div style={{width: width, height: height, marginBottom: '100px'}}>
        <PlaceAutocompleteClassic onPlaceSelect={setSelectedPlace} register={register} errors={errors} selectedLocation={selectedLocation}/>            
        <Map
          defaultZoom={14}
          defaultCenter={position}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          <Marker position={position} />
        </Map>      
      </div>
      <MapHandler place={selectedPlace} />
    </APIProvider>
  );
};


const MapHandler = ({place}) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
};



const PlaceAutocompleteClassic = ({onPlaceSelect, register, errors, selectedLocation}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
      componentRestrictions: { country: 'ar' } 

    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container">
      <input 
        id="address"
        defaultValue={selectedLocation?.address}
        {...register('address')} 
        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
        ref={inputRef} 
      />
      {errors.address && <div className="invalid-feedback">{errors.address.message}</div>}

    </div>
  );
};

export default GoogleMapWithAutocomplete;