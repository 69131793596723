import React from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import PromotionForm from '../../components/PromotionForm';
import usePromotion from '../../hooks/usePromotion';
import './EditPizzeriaPage.css';  // Ensure to create and style this CSS file

const EditPromotionPage = () => {
  const { id } = useParams();
  
  const { promotionData, loading: promotionLoading, error: promotionError } = usePromotion(id);  

  if (promotionError) {
    return <p>Error: {promotionError.message}</p>;
  }

  return (
    <div className="pizzeria-page cms">      
      <div className="main-content">        
          <Sidebar/>        
        <div className="content">
          {promotionLoading && <p>Cargando...</p>}
          {!promotionLoading && 
            <>
              <h2>{!promotionData.id ? 'Crear Promocion' : 'Editar Promocion'}</h2>    
              <hr/>   
              <PromotionForm 
                promotionData={promotionData}                 
              />
            </>
          }
          </div>
      </div>
    </div>
  );
};

export default EditPromotionPage;
