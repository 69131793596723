import React from 'react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import './PromotionForm.css';
import { extractErrors } from '../../utils/errors';
import { useAuth } from '../provider/auth-context'; 
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { savePromotion } from '../services/api';
import { create } from 'lodash';



const PromotionForm = ({promotionData}) => {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo requerido'),
  });

  const { register, handleSubmit, control, formState: { errors }, setValue, trigger, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });
  
  const onSubmit = async (data) => {
    if(data.id){
      updatePromotion(data);
    }
    else{
      createPromotion(data);
    }
  };

  const createPromotion = async (data) => {
    toast.dismiss();
    setLoading(true);
    const toastId = toast.loading("Guardando Promocion...");
    try {
      const {id} = await savePromotion(data);     
      data.id = id;
      toast.update(toastId, { render: "Promocion creada!", type: "success", theme:"colored", position:"top-center",isLoading: false,  autoClose: 5000 });      
    } catch (error) {
      const errors = extractErrors(error.response);        
      errors.forEach(error => toast.error(error));            
    } finally {
      setLoading(false);
    }
  }

  const updatePromotion = async (data) => {
    toast.dismiss();
    setLoading(true);
    const toastId = toast.loading("Guardando Promocion...");
    try {
      const {id} = await savePromotion(data);     
      data.id = id;
      toast.update(toastId, { render: "Promocion actualizada!", type: "success", theme:"colored", position:"top-center",isLoading: false,  autoClose: 5000 });      
    } catch (error) {
      const errors = extractErrors(error.response);        
      errors.forEach(error => toast.error(error));            
    } finally {
      setLoading(false);
    }
  }


  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>         
      {loading && <Spinner />}
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>        
        <div className="form-group">
          <label htmlFor="name">Nombre de la promocion</label>
          <input
            type="text"
            id="name"
            defaultValue={promotionData.name}
            {...register('name')}
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          />
          {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
        </div>
        <ToastContainer />
        <div style={{position:'fixed', bottom: 0, width: '100%', padding: '10px 0', backgroundColor: 'white'}}>
          <div className="form-group">
            <button type="submit" className="px-5 btn btn-custom-primary">Guardar</button>
            {user.admin && <button type="button" className="mx-5 px-5 btn btn-custom-secondary" onClick={handleBack}>Volver</button>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PromotionForm;
